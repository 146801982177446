import React from 'react';

export const Style = props => {
  const {images} = props;

  return (
    <div>
      hola
      {
        console.log(images)
        /* images.map(
          (item,key) => (
          <a key={key} class="image" href={ `#` + item.id}>
            <img src={item.url} />
            <form class="style-preselection" action="/checkout" method="post">
                <button name="selected_style" class="style-preselection__cta" value="' . $style->style_id . '">Order this style</button>
            </form>
          </a>
        )) */
      }
    </div>
  );
}
export default Style;