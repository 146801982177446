import React, {useState,useEffect } from "react";

import Api from '../api/api';
import Styles from '../components/Styles/Styles'
// Initialize Api client
const api = new Api(process.env.GATSBY_API_URL);
let images_s = [];



const Gallery = () => {
  let [images, setImages] = useState([]);
  useEffect(() => {
    if(images_s.length > 0){
      console.log('heeey');
      setImages(images_s);
    }
  }, [images_s]);
  
  const getImages = () => {
    api.getImages('S','Photologo 2022-02',(res) => {
      if(res.data.exhibitions.totalCount > 0){
          images_s = res.data.exhibitions.edges.map(edge => {
          let categories_s = [];
          if(edge.node.categories.edges.length > 1){
              edge.node.categories.edges.forEach(element => {
                  categories_s.push(element.node.slug)
              });
              return {
                  id: edge.node.id,
                  alt: edge.node.alt.split('-').pop(),
                  name: edge.node.name,
                  url: edge.node.url,
                  type: edge.node.type,
                  category: categories_s.toString()
              }
          }else{
              if(edge.node.categories.edges.length > 0){
                  return {
                      id: edge.node.id,
                      alt: edge.node.alt.split('-').pop(),
                      name: edge.node.name,
                      url: edge.node.url,
                      type: edge.node.type,
                      category: edge.node.categories.edges[0].node.slug
                  }
              }else{
                  return {
                      id: edge.node.id,
                      alt: edge.node.alt.split('-').pop(),
                      name: edge.node.name,
                      url: edge.node.url,
                      type: edge.node.type,
                      category: null
                  }
              }
          }
      });
      console.log(images_s)
      }
    })
   }
   
  return(
    <React.Fragment>
    <h1>Gallery</h1>
    <button onClick={getImages}>Fetch images</button>
    { images.length > 0 &&
      <Styles images={images}></Styles>
    }
   </React.Fragment>
  );
    
}






 
 export default Gallery;